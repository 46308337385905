import * as TriviaStorage from "../model/TriviaStorage"
import {
  Layouts_allLayoutsJson_edges_node_node,
  Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node,
} from "../types/api"
import { positionArray, position } from "../components/ad"

export function shuffle(array: any[]) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export function getLayout() {
  let utmSource = TriviaStorage.getUtmSource()
  if (!utmSource) {
    utmSource = "def"
  }
  require("es6-promise").polyfill()
  require("isomorphic-fetch")

  return fetch(`${process.env.GATSBY_URL_PREFIX}/layouts/layout-${utmSource}.json`)
    .then(response => response.json())
    .then(responseData => {
      return responseData
    })
    .catch(error =>
      fetch(`${process.env.GATSBY_URL_PREFIX}/layouts/layout-def.json`)
        .then(response => response.json())
        .then(responseData => {
          return responseData
        })
        .catch(error => console.log("ads-layout-loading-error"))
    )
}

export function selectLayoutVariant(
  layout: Layouts_allLayoutsJson_edges_node_node,
  page: number
): Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node {
  let defaultVariant = null
  let pageVariant = null
  layout.variantSet.edges.forEach(({ node: variant }) => {
    if (variant.pageFrom === 0 && variant.pageTo === 0) {
      defaultVariant = variant
    } else if (variant.pageFrom <= page && variant.pageTo >= page) {
      pageVariant = variant
    } else if (variant.pageFrom <= page && variant.pageTo === 0) {
      pageVariant = variant
    }
  })

  if (pageVariant) {
    return pageVariant
  } else {
    return defaultVariant
  }
}

export function clearSnappy(result = false) {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    window.loadPersistUnits = Array()

    positionArray.forEach(element => {
      if (
        result &&
        element == position.RIGHT_RAIL_LOWER &&
        element == position.RIGHT_RAIL_MIDDLE &&
        element == position.RIGHT_RAIL_UPPER
      ) {
      } else {
        document.getElementById(element + "-PERSIST").innerHTML = ""
        var snappy = document.getElementById(element + "-PERSIST")
        // kill the interval
        clearInterval(snappy.followInterval)
        // make snappy disappear
        snappy.style.position = null
        snappy.style.top = "0px"
        snappy.style.right = "0px"
        snappy.style.left = ""
        snappy.style.width = "auto"

        var name = element + "_WIPE"
        if (typeof window[name] === "function") {
          window[name]()
        }
        document.getElementById("WIPE").innerHTML = ""
      }
    })
  }
}

export function editStr(text: String) {
  return text.replace(/\s+|\+|\/|\=/g, "").substr(0, 30)
}
