import * as React from "react"
import { Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node } from "../types/api"
import { editStr } from "../model/helpers"
import { Layout } from "./layout"

export const position = {
  ABOVE_CORRECT_ANSWER: "ABOVE_CORRECT_ANSWER",
  BELOW_CORRECT_ANSWER: "BELOW_CORRECT_ANSWER",
  ABOVE_HEADLINE: "ABOVE_HEADLINE",
  BELOW_HEADLINE: "BELOW_HEADLINE",
  ABOVE_BUTTONS: "ABOVE_BUTTONS",
  BELOW_BUTTONS_1: "BELOW_BUTTONS_1",
  BELOW_BUTTONS_2: "BELOW_BUTTONS_2",
  BELOW_BUTTONS_3: "BELOW_BUTTONS_3",
  BELOW_SECOND_BUTTONS_1: "BELOW_SECOND_BUTTONS_1",
  BELOW_SECOND_BUTTONS_2: "BELOW_SECOND_BUTTONS_2",
  BELOW_SECOND_BUTTONS_3: "BELOW_SECOND_BUTTONS_3",
  BELOW_SCORE: "BELOW_SCORE",
  ABOVE_ANSWER_PIC: "ABOVE_ANSWER_PIC",
  BELOW_ANSWER_PIC: "BELOW_ANSWER_PIC",
  PAGE_LEVEL_1: "PAGE_LEVEL_1",
  PAGE_LEVEL_2: "PAGE_LEVEL_2",
  PAGE_LEVEL_3: "PAGE_LEVEL_3",
  RIGHT_RAIL_UPPER: "RIGHT_RAIL_UPPER",
  RIGHT_RAIL_MIDDLE: "RIGHT_RAIL_MIDDLE",
  RIGHT_RAIL_LOWER: "RIGHT_RAIL_LOWER",
  BELOW_RESPONSE: "BELOW_RESPONSE",
  PARAGRAPH_SPLITTER: "PARAGRAPH_SPLITTER",
}

export const positionArray = [
  position.ABOVE_CORRECT_ANSWER,
  position.BELOW_CORRECT_ANSWER,
  position.ABOVE_HEADLINE,
  position.BELOW_HEADLINE,
  position.ABOVE_BUTTONS,
  position.BELOW_BUTTONS_1,
  position.BELOW_BUTTONS_2,
  position.BELOW_BUTTONS_3,
  position.BELOW_SECOND_BUTTONS_1,
  position.BELOW_SECOND_BUTTONS_2,
  position.BELOW_SECOND_BUTTONS_3,
  position.BELOW_SCORE,
  position.ABOVE_ANSWER_PIC,
  position.BELOW_ANSWER_PIC,
  position.PAGE_LEVEL_1,
  position.PAGE_LEVEL_2,
  position.PAGE_LEVEL_3,
  position.RIGHT_RAIL_UPPER,
  position.RIGHT_RAIL_MIDDLE,
  position.RIGHT_RAIL_LOWER,
  position.BELOW_RESPONSE,
  position.PARAGRAPH_SPLITTER,
]

declare global {
  interface Window {
    adUnitConfig: Array<object>
    loadPersistUnits: Array<string>
    vendors: Array<string>
  }
}

type Props = {
  position: string
  layout: Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node
  layoutPlus?: Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node
  resultPage?: boolean
}

export class Ad extends React.PureComponent<Props> {
  componentDidUpdate() {
    let ad = null
    let snappy = false
    let slotName = ""
    if (this.props.layout) {
      this.props.layout.slotSet.edges.forEach(({ node: slot }) => {
        if (slot.slot === this.props.position) {
          slotName = this.props.position
          if (slot.unit.persistent) {
            slotName = this.props.position + "-PERSIST"
          }
          if (window.loadPersistUnits[slotName] != slot.unit.id) {
            let slotId = editStr(slot.unit.id)
            ad = "initUnit" + slotId
            if (typeof window[ad] === "function") {
              window.adUnitConfig[slotId] = window.adUnitConfig[slotId] || {}
              window[ad](document.getElementById(slotName), window.adUnitConfig[slotId])
            }
          }
          if (slot.unit.persistent) {
            if (window.loadPersistUnits[slotName] == slot.unit.id) {
              let slotId = editStr(slot.unit.id)
              const persistAd = "refreshUnit" + slotId
              if (typeof window[persistAd] === "function") {
                window.adUnitConfig[slotId] = window.adUnitConfig[slotId] || {}
                window[persistAd](document.getElementById(slotName), window.adUnitConfig[slotId])
              }
            }
            if (window.loadPersistUnits[slotName] != slot.unit.id) {
              snappy = true
            }
            window.loadPersistUnits[slotName] = slot.unit.id
          }
        }
      })
    }

    if (ad !== null && snappy) {
      this.snappyFollow(slotName)
    }
  }

  render() {
    return ""
  }

  private snappyFollow(slotID: string) {
    var snappy = document.getElementById(slotID)
    snappy.followInterval = setInterval(() => {
      // make snappy cover the slot
      var slotId = slotID
      var slot = document.getElementById(slotId.replace("-PERSIST", ""))
      if (slot) {
        var position = this.getCoords(slot)
        snappy.style.top = position.top + "px"
        snappy.style.left = position.left + "px"
        snappy.style.right = ""
        snappy.style.width = position.width + "px"
        snappy.style.position = "absolute"

        // make slot as high as snappy
        var snappyHeight = this.getCoords(snappy)["height"]
        slot.style.height = snappyHeight + "px"
      }
    }, 200)
  }

  private getCoords(elem: HTMLElement) {
    var box = elem.getBoundingClientRect()

    var body = document.body
    var docEl = document.documentElement

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    var clientTop = docEl.clientTop || body.clientTop || 0
    var clientLeft = docEl.clientLeft || body.clientLeft || 0

    var top = box.top + scrollTop - clientTop
    var left = box.left + scrollLeft - clientLeft

    return {
      top: Math.round(top),
      left: Math.round(left),
      width: box.width,
      height: box.height,
    }
  }
}

export class VendorInit extends React.PureComponent<Props> {
  componentDidUpdate() {
    if (window.loadPersistUnits == null) {
      window.loadPersistUnits = Array()
    }
    if (window.vendors == null) {
      window.vendors = Array()
    }
    document.getElementById("WIPE").innerHTML = ""

    if (this.props.layout) {
      this.props.layout.slotSet.edges.forEach(({ node: slot }) => {
        if (!window.vendors.includes(slot.unit.vendor.id)) {
          window.vendors.push(slot.unit.vendor.id)
          const name = "initVendor" + editStr(slot.unit.vendor.id)
          if (typeof window[name] === "function") {
            window[name]()
          }
        }
      })
    }

    if (!window.adUnitConfig) {
      window.adUnitConfig = Array()
    }
  }

  render() {
    return ""
  }
}

export class Wipe extends React.PureComponent<Props> {
  componentWillUnmount() {
    if (this.props.layout) {
      let loadSlots = Array()
      // all units on actual page
      this.props.layout.slotSet.edges.forEach(({ node: slot }) => {
        loadSlots[slot.slot] = editStr(slot.unit.id)
      })
      // remove persistant units exist on next page
      if (this.props.layoutPlus) {
        this.props.layoutPlus.slotSet.edges.forEach(({ node: slot }) => {
          if (slot.unit.persistent && loadSlots[slot.slot] == editStr(slot.unit.id)) {
            delete loadSlots[slot.slot]
          }
        })
      }
      // wipe units
      for (let key in loadSlots) {
        if (typeof window["wipeUnit" + loadSlots[key]] === "function") {
          window["wipeUnit" + loadSlots[key]](document.getElementById(key), window.adUnitConfig[loadSlots[key]])
        }
        document.getElementById(key + "-PERSIST").innerHTML = ""
        if (key + "-PERSIST" in window.loadPersistUnits) {
          window.loadPersistUnits[key + "-PERSIST"] = ""
        }
        this.snappyKill(key)
      }
    }
  }

  render() {
    return ""
  }

  private snappyKill(slotID: string) {
    var snappy = document.getElementById(slotID + "-PERSIST")

    // kill the interval
    clearInterval(snappy.followInterval)

    // make snappy disappear
    snappy.style.top = "0px"
    snappy.style.right = "0px"
    snappy.style.left = ""
    snappy.style.width = "auto"
    snappy.style.position = null
  }
}
