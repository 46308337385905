import { graphql, Link, StaticQuery } from "gatsby"
import { Tags } from "../types/api"
import * as React from "react"

type Props = {
  slug?: string
}

export const MenuTags = (props: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query Tags {
          allTagsJson {
            edges {
              node {
                node {
                  id
                  menuPosition
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data: Tags) => {
        const tagsToShow = [...data.allTagsJson.edges.filter(e => e.node.node.menuPosition !== null)]
          .map(e => e.node.node)
          .sort((a, b) => (a.menuPosition < b.menuPosition ? -1 : 1))
        return tagsToShow.map(tag => (
          <li
            key={tag.id}
            itemScope
            itemType="https://www.schema.org/SiteNavigationElement"
            className={`menu-item menu-item-type-post_type menu-item-object-page ${
              props.slug === tag.slug ? "active" : ""
            }`}
          >
            <a href={process.env.GATSBY_URL_PREFIX + `/${tag.slug}/`} title={tag.title}>
              {tag.title}
            </a>
          </li>
        ))
      }}
    />
  )
}
