import * as React from "react"
import { MenuTags } from "./MenuTags"
import { Link } from "gatsby"

const logo = require("../images/trivia_boss_logo-36.png")
type Props = {
  isQuizList?: boolean
  slug?: string
}

export const Footer = (props: Props) => {
  return (
    <footer className="footer-text pad-bottom-15 pad-top-15">
      <div className="container-fluid" style={{ paddingBottom: "5px" }}>
        <div className="row">
          <div className="col-sm-12">
            <div className="footer-logo">
              <img src={logo} alt="TriviaBoss logo" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid legal-links <?php echo $footer_bg_class; ?>">
        <div className="row">
          <div className="col-sm-12">
            <ul id="menu-secondary-menu-1" className="nav navbar-nav">
              <MenuTags slug={props.slug} />
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3552"
              >
                <a href={process.env.GATSBY_URL_PREFIX + "/about-us/"} title="About Us">
                  About Us
                </a>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9471"
              >
                <a href={process.env.GATSBY_URL_PREFIX + "/contact-us/"} title="Contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ul id="menu-legal" className="nav navbar-nav">
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-3545"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3545"
              >
                <a title="Privacy Policy" href={process.env.GATSBY_URL_PREFIX + "/privacy/"}>
                  Privacy Policy
                </a>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-3544"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3544"
              >
                <a title="Terms Of Service" href={process.env.GATSBY_URL_PREFIX + "/terms-of-service/"}>
                  Terms Of Service
                </a>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-3543"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3543"
              >
                <a title="DMCA" href={process.env.GATSBY_URL_PREFIX + "/dmca/"}>
                  DMCA
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12" id="footer-copyright">
            © Grey State Ventures, 2019&nbsp;&nbsp;
          </div>
        </div>
      </div>
    </footer>
  )
}
