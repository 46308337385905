import { Quiz } from "./types"
import localStorageMemory from "localstorage-memory"

const queryString = require("query-string")

const storage = typeof window !== "undefined" ? window.localStorage : localStorageMemory

const quizStorageKey = (slug: string) => `quiz-${slug}`
export const getQuiz = (slug: string): Quiz | null => JSON.parse(storage.getItem(quizStorageKey(slug)))
export const setQuiz = (slug: string, obj: Quiz) => storage.setItem(quizStorageKey(slug), JSON.stringify(obj))

export const saveUtmFromLocation = (location: any) => {
  let utmSource = queryString.parse(location.search).utm_source
  let v = queryString.parse(location.search).v
  if (utmSource) {
    setUtmSource(utmSource)
  }
  if (v) {
    setUtmSource(v)
  }

  let utmMedium = queryString.parse(location.search).utm_medium
  if (utmMedium) {
    setUtmMedium(utmMedium)
  }
  let utmCampaign = queryString.parse(location.search).utm_campaign
  if (utmCampaign) {
    setUtmCampaign(utmCampaign)
  }
  let utmTerm = queryString.parse(location.search).utm_term
  if (utmTerm) {
    setUtmTerm(utmTerm)
  }
  let utmContent = queryString.parse(location.search).utm_content
  if (utmContent) {
    setUtmContent(utmContent)
  }
  let ep = queryString.parse(location.search).ep
  if (ep) {
    setEp(Number(ep))
  }
  let em = queryString.parse(location.search).em
  if (em) {
    setEm(em)
  }
  let emMd5 = queryString.parse(location.search).em_md5
  if (emMd5) {
    setEmMd5(emMd5)
  }
  let emSha1 = queryString.parse(location.search).em_sha1
  if (emSha1) {
    setEmSha1(emSha1)
  }
  let emSha256 = queryString.parse(location.search).em_sha256
  if (emSha256) {
    setEmSha256(emSha256)
  }
  let r = queryString.parse(location.search).r
  if (r) {
    setEntryConversion(true)
  }
}
const utmParamKey = "utmSource"
export const getUtmSource = (): string | null => JSON.parse(storage.getItem(utmParamKey))
export const setUtmSource = (value: string) => storage.setItem(utmParamKey, JSON.stringify(value))

const utmMediumParamKey = "utmMedium"
export const getUtmMedium = (): string | null => JSON.parse(storage.getItem(utmMediumParamKey))
export const setUtmMedium = (value: string) => storage.setItem(utmMediumParamKey, JSON.stringify(value))

const utmCampaignParamKey = "utmCampaign"
export const getUtmCampaign = (): string | null => JSON.parse(storage.getItem(utmCampaignParamKey))
export const setUtmCampaign = (value: string) => storage.setItem(utmCampaignParamKey, JSON.stringify(value))

const utmTermParamKey = "utmTerm"
export const getUtmTerm = (): string | null => JSON.parse(storage.getItem(utmTermParamKey))
export const setUtmTerm = (value: string) => storage.setItem(utmTermParamKey, JSON.stringify(value))

const utmContentParamKey = "utmContent"
export const getUtmContent = (): string | null => JSON.parse(storage.getItem(utmContentParamKey))
export const setUtmContent = (value: string) => storage.setItem(utmContentParamKey, JSON.stringify(value))

const epParamKey = "ep"
export const getEp = (): number | null => JSON.parse(storage.getItem(epParamKey))
export const setEp = (value: number) => storage.setItem(epParamKey, JSON.stringify(value))

const emParamKey = "em"
export const getEm = (): string | null => JSON.parse(storage.getItem(emParamKey))
export const setEm = (value: string) => storage.setItem(emParamKey, JSON.stringify(value))

const emMd5ParamKey = "emMd5"
export const getEmMd5 = (): string | null => JSON.parse(storage.getItem(emMd5ParamKey))
export const setEmMd5 = (value: string) => storage.setItem(emMd5ParamKey, JSON.stringify(value))

const emSha1ParamKey = "emSha1"
export const getEmSha1 = (): string | null => JSON.parse(storage.getItem(emSha1ParamKey))
export const setEmSha1 = (value: string) => storage.setItem(emSha1ParamKey, JSON.stringify(value))

const emSha256ParamKey = "emSha256"
export const getEmSha256 = (): string | null => JSON.parse(storage.getItem(emSha256ParamKey))
export const setEmSha256 = (value: string) => storage.setItem(emSha256ParamKey, JSON.stringify(value))

const entryConversion = "entryConversion"
export const getEntryConversion = (): boolean | null => JSON.parse(storage.getItem(entryConversion))
export const setEntryConversion = (value: boolean) => storage.setItem(entryConversion, JSON.stringify(value))

const loadDelayMinKey = "loadDelayMin"
export const getLoadDelayMin = (): number | null =>
  storage.getItem(loadDelayMinKey) ? JSON.parse(storage.getItem(loadDelayMinKey)) : null
export const setLoadDelayMin = (value: number) => storage.setItem(loadDelayMinKey, JSON.stringify(value))
const loadDelayMaxKey = "loadDelayMax"
export const getLoadDelayMax = (): number | null =>
  storage.getItem(loadDelayMaxKey) ? JSON.parse(storage.getItem(loadDelayMaxKey)) : null
export const setLoadDelayMax = (value: number) => storage.setItem(loadDelayMaxKey, JSON.stringify(value))
