import * as React from "react"
import { Link } from "gatsby"
import { MenuTags } from "./MenuTags"

const logo = require("../images/trivia_boss_logo-36.png")

type Props = {
  slug?: string
}

export const PrimaryHeader = (props: Props) => (
  <nav className="main-nav navbar-default" role="navigation">
    <div className="container-fluid">
      <div className="navbar-header">
        <div className="brand-wrapper">
          <a className="navbar-brand logo-all" href={process.env.GATSBY_URL_PREFIX + "/"} data-filter=".all">
            <img className="expandUp" id="logo" alt="TriviaBoss logo" src={logo} />
          </a>
        </div>
      </div>
      <ul className="nav navbar-nav">
        <div className="button-group filter-button-group">
          <div className="menu-header-navigation-container">
            <ul id="menu-header-navigation" className="nav navbar-nav">
              <MenuTags slug={props.slug} />
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className={`menu-item menu-item-type-post_type menu-item-object-page ${
                  props.slug === "about-us" ? "active" : ""
                }`}
              >
                <a href={process.env.GATSBY_URL_PREFIX + "/about-us/"} title="About Us">
                  About Us
                </a>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className={`menu-item menu-item-type-post_type menu-item-object-page ${
                  props.slug === "contact-us" ? "active" : ""
                }`}
              >
                <a href={process.env.GATSBY_URL_PREFIX + "/contact-us/"} title="Contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </div>
  </nav>
)

export const SecondaryHeader = (props: Props) => (
  <nav className="navbar navbar-default" role="navigation">
    <div className="navbar-header">
      <div className="quiz-brand-wrapper">
        <button
          className="navbar-toggle navbar-toggle-right"
          type="button"
          data-toggle="collapse"
          data-target=".navbar-collapse"
        >
          Menu
        </button>
        <a className="navbar-brand" href={process.env.GATSBY_URL_PREFIX + "/"}>
          <img id="logo" alt="TriviaBoss logo" src={logo} />
        </a>
      </div>
    </div>
    <div className="collapse navbar-collapse navbar-ex1-collapse">
      <ul id="menu-secondary-menu" className="nav navbar-nav">
        <MenuTags slug={props.slug} />
        <li
          itemScope
          itemType="https://www.schema.org/SiteNavigationElement"
          className={`menu-item menu-item-type-post_type menu-item-object-page ${
            props.slug === "about-us" ? "active" : ""
          }`}
        >
          <a href={process.env.GATSBY_URL_PREFIX + "/about-us"} title="About Us">
            About Us
          </a>
        </li>
        <li
          itemScope
          itemType="https://www.schema.org/SiteNavigationElement"
          className={`menu-item menu-item-type-post_type menu-item-object-page ${
            props.slug === "contact-us" ? "active" : ""
          }`}
        >
          <a href={process.env.GATSBY_URL_PREFIX + "/contact-us/"} title="Contact">
            Contact
          </a>
        </li>
      </ul>
    </div>
  </nav>
)
