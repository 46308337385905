/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import * as TriviaStorage from "../model/TriviaStorage"

type Props = {
  title: string
  type?: string
  description?: string
  lang?: "cs" | "en"
  meta?: any[]
  keywords?: string[]
  isQuiz?: boolean
  quizPage?: number
  url?: string
  img?: any
}

export const SEO = (props: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            author
            site
          }
        }
      }
    `
  )

  const metaDescription = props.description || props.title
  const metaTitle = props.title + " - Trivia Boss"
  const metaType = props.type || "article"

  let metaUrl = site.siteMetadata.site + "/"
  if (props.url && typeof props.url !== "undefined" && props.url !== "undefined") {
    metaUrl = site.siteMetadata.site + "/" + props.url + "/"
  }

  let metaImg = site.siteMetadata.site + "/TriviaBoss-Logo.png"
  if (props.img && typeof props.img !== "undefined" && props.img !== "undefined") {
    metaImg = site.siteMetadata.site + props.img.src
  }

  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://triviaboss.com/",
    sameAs: ["https://www.facebook.com/policulous/", "https://www.youtube.com/channel/UCjq-xp_9sUVNHfqk1JmMxUw"],
    "@id": "https://triviaboss.com/#organization",
    name: "TriviaBoss",
    logo: "",
  }

  let metaSocial = [
    {
      property: `og:locale`,
      content: "en_US",
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: metaType,
    },
    {
      property: `og:url`,
      content: metaUrl,
    },
    {
      property: `og:site_name`,
      content: "Trivia Boss",
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: metaImg,
    },
    {
      name: `og:image`,
      content: metaImg,
    },
    {
      name: `og:image:secure_url`,
      content: metaImg,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang || "en",
      }}
      title={props.title}
      titleTemplate={`%s - Trivia Boss`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ]
        .concat(props.url != "sponsored" ? metaSocial : [])
        .concat(
          props.url == "sponsored"
            ? {
                name: `robots`,
                content: `noindex, nofollow`,
              }
            : []
        )
        .concat(
          props.keywords && props.keywords.length > 0
            ? {
                name: `keywords`,
                content: props.keywords.join(`, `),
              }
            : []
        )
        .concat(props.meta || [])}
    >
      {(TriviaStorage.getEmMd5() || TriviaStorage.getEmSha1() || TriviaStorage.getEmSha256()) && (
        <script type="text/javascript">{`
          var properSpecialOps = properSpecialOps || {};
          properSpecialOps['liveramp_emailHashes'] = { md5: '${
            TriviaStorage.getEmMd5() ? TriviaStorage.getEmMd5() : ""
          }', sha1: '${TriviaStorage.getEmSha1() ? TriviaStorage.getEmSha1() : ""}', sha256: '${
          TriviaStorage.getEmSha256() ? TriviaStorage.getEmSha256() : ""
        }' };
          properSpecialOps['liveramp'] = 1;
        `}</script>
      )}
      {metaUrl && props.url != "sponsored" && <link rel="canonical" href={metaUrl} />}
      {props.isQuiz && (
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.0.12/css/all.css"
          integrity="sha384-G0fIWCsCzJIMAVNQPfjH08cyYaUtMwjJwqiRKxxE/rx96Uroj1BtIQ6MLJuheaO9"
          crossOrigin="anonymous"
        />
      )}
      {props.isQuiz && (
        <link
          rel="stylesheet"
          id="redux-google-fonts-wbc907_data-css"
          href="https://fonts.googleapis.com/css?family=Roboto%3A900%7CAbril+Fatface%3A400&amp;ver=4.9.5"
          type="text/css"
          media="all"
        />
      )}
      {props.isQuiz && (
        <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" rel="stylesheet" />
      )}
      {!props.isQuiz && <script src="https://cdn.rawgit.com/meetselva/attrchange/master/js/attrchange.js" />}
      {!props.isQuiz && <script src="https://cdn.rawgit.com/meetselva/attrchange/master/js/attrchange_ext.js" />}
      {!props.isQuiz && <script src="//cdnjs.cloudflare.com/ajax/libs/lettering.js/0.7.0/jquery.lettering.min.js" />}
      {!props.isQuiz && <script src="//cdnjs.cloudflare.com/ajax/libs/gsap/1.18.0/TweenMax.min.js" />}
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
    </Helmet>
  )
}
